import { ref, getCurrentInstance } from 'vue';

export default function useNavigation() {
  const siteConfig = getCurrentInstance()?.appContext.config.globalProperties.siteConfig;

  const dropdownItems = ref([
    {
      game: 'magic',
      title: 'Magic',
      home: '/magic-the-gathering',
      articles: '/magic-the-gathering/articles',
      bestiary: '/magic-the-gathering/commander-bestiary',
      channelfireball: '/magic-the-gathering/channelfireball',
      premier: '/magic-the-gathering/articles/premier',
      decks: '/magic-the-gathering/decks',
      search: '/magic-the-gathering/decks/advanced-search',
      priceGuide: `${process.env.VUE_APP_TCGPLAYER_ROOT_URL}/categories/trading-and-collectible-card-games/magic-the-gathering/price-guides`,
      shop: `${process.env.VUE_APP_TCGPLAYER_ROOT_URL}/search/magic/product?productLineName=magic`,
      open: false,
      isDropdownMenu: true,
      includeMobile: true,
    },
    {
      game: 'yugioh',
      title: 'Yu-Gi-Oh!',
      home: '/yugioh',
      articles: '/yugioh/articles',
      decks: '/yugioh/decks',
      search: '/yugioh/decks/advanced-search',
      priceGuide: `${process.env.VUE_APP_TCGPLAYER_ROOT_URL}/categories/trading-and-collectible-card-games/yugioh/price-guides`,
      shop: `${process.env.VUE_APP_TCGPLAYER_ROOT_URL}/search/yugioh/product?productLineName=yugioh`,
      open: false,
      isDropdownMenu: true,
      includeMobile: true,
    },
    {
      game: 'pokemon',
      title: 'Pokémon',
      home: '/pokemon',
      articles: '/pokemon/articles',
      premier: '/pokemon/articles/premier',
      priceGuide: `${process.env.VUE_APP_TCGPLAYER_ROOT_URL}/categories/trading-and-collectible-card-games/pokemon/price-guides`,
      shop: `${process.env.VUE_APP_TCGPLAYER_ROOT_URL}/search/pokemon/product?productLineName=pokemon`,
      open: false,
      isDropdownMenu: true,
      includeMobile: true,
    },
    {
      game: 'lorcana',
      title: 'Disney Lorcana',
      home: '/disney-lorcana',
      articles: '/disney-lorcana/articles',
      decks: '/disney-lorcana/decks',
      priceGuide: `${process.env.VUE_APP_TCGPLAYER_ROOT_URL}/categories/trading-and-collectible-card-games/disney-lorcana/price-guides`,
      shop: `${process.env.VUE_APP_TCGPLAYER_ROOT_URL}/search/lorcana-tcg/product?productLineName=lorcana-tcg`,
      open: false,
      isDropdownMenu: true,
      includeMobile: true,
    },
    {
      game: 'fab',
      title: 'Flesh and Blood',
      home: '/flesh-and-blood',
      articles: '/flesh-and-blood/articles',
      priceGuide: `${process.env.VUE_APP_TCGPLAYER_ROOT_URL}/categories/trading-and-collectible-card-games/flesh-and-blood-tcg/price-guides`,
      shop: `${process.env.VUE_APP_TCGPLAYER_ROOT_URL}/search/flesh-and-blood-tcg/product?productLineName=flesh-and-blood-tcg`,
      open: false,
      isDropdownMenu: true,
      includeMobile: true,
    },
    {
      title: 'More Games',
      open: false,
      home: '/more-games',
      includeMobile: true,
      isDropdownMenu: true,
      childRoutes: siteConfig('global.moreGamesLinks'),
    },
    {
      title: 'TCGplayer Edge',
      home: '/tcgplayer-edge',
      includeMobile: true,
    }
  ]);

  return {
    dropdownItems,
  };
}
