import { useFeature } from '@tcgplayer/martech-components';
import { useUser } from '@/use/useUser';

export default function useFeatureHeader() {
  const { isOptimizelyFeatureEnabled } = useFeature();
  const { userKey } = useUser();

  const isMpHeaderEnabled = isOptimizelyFeatureEnabled('mt-infinite-header', { userKey });

  return {
    isMpHeaderEnabled,
  };
}
