<template>
  <delay-load @visible="onVisible">
    <card-spotlight-price-history
      v-if="render"
      :data="cardData"
      :loaded="render"
      :is-flipped="isFlipped"
      :card-product-url="cardBuyUrl"
      :card-id="cardId"
      :is-product="isProduct"
      :image-dimensions="imageDimensions"
      :feature-flag="isOpenLinkSameTabEnabled"
      @amplitude-event="sendClickEvent"
      @flip="isFlipped = !isFlipped"/>
    <placeholder v-if="!render && !error" :image-height="deviceType === 'mobile' ? '950px' : '600px'" :placeholder-height="deviceType === 'mobile' ? '1020px' : '675px'"/>
  </delay-load>
</template>

<script setup>
import { computed, ref, getCurrentInstance } from 'vue';
import { get, set } from '@vueuse/core';
import { useStore } from 'vuex';
import Api from '@/api/api';

import amplitudeEvent from '@tcgplayer/amplitude';

import {
  CardSpotlightPriceHistory, LinkHelper as link, useDeviceType, VerticalHelpers as verts
} from '@tcgplayer/martech-components';
import DelayLoad from '@/components/DelayLoad.vue';
import Placeholder from '@/components/placeholder-components/FullArtPlaceholder.vue';

const props = defineProps({
  cardFlipImage: {
    type: String,
    required: false,
    default: '',
  },
  cardId: {
    type: [ Number, String, ],
    required: false,
    default: '',
  },
  isProduct: {
    type: Boolean,
    required: false,
    default: false,
  },
  show: {
    type: Boolean,
    required: false,
    default: true,
  },
  cardName: {
    type: String,
    required: false,
    default: '',
  },
  cardImage: {
    type: String,
    required: false,
    default: '',
  },
  priceMarket: {
    type: Number,
    required: false,
    default: null,
  },
  cardProductUrl: {
    type: String,
    required: false,
    default: '',
  },
  cardVertical: {
    type: String,
    required: false,
    default: 'Magic',
  },
  variantId: {
    type: String,
    required: false,
    default: '',
  },
  variantSet: {
    type: String,
    required: false,
    default: '',
  },
});

const isOpenLinkSameTabEnabled = true;

const { deviceType } = useDeviceType();
const store = useStore();
// eslint-disable-next-line no-underscore-dangle
const componentName = getCurrentInstance()?.type.__name;

const render = ref(false);
const error = ref(false);

const data = ref({});
const productUrl = ref();
const flipImage = ref();
const image = ref();
const layout = ref();
const name = ref();
const rarity = ref();
const setName = ref();
const description = ref();
const mp = ref();
const pricePoints = ref({
  Normal: {},
  Foil: {},
});
const tcgPlayerID = ref();
const cardType = ref();
const game = ref();
const cardData = ref({});
const isFlipped = ref(false);

const analytics = computed(() => store.state.article.analytics);
const article = computed(() => store.state.article.article);
const author = computed(() => store.state.article.author);

const cardBuyUrl = computed(() => link.urlUtmBuilder(get(productUrl), get(analytics)));

const imageDimensions = computed(() => {
  if (props.isProduct) {
    return get(deviceType) === 'desktop' ? { width: '300px' } : { width: '310px' };
  }

  return get(deviceType) === 'desktop' ? { width: '381px', height: '532px' } : { width: '310px', height: '432px' };
});

const getImageURL = (imageURL, tcgURL) => {
  if (imageURL) {
    return imageURL.replace('/large/', '/normal/');
  }

  return tcgURL;
};

const processResponse = (response) => {
  set(data, response?.data?.result?.card || response?.data?.result || {});
  set(tcgPlayerID, get(data)?.tcgPlayerID || get(data)?.id);
  set(image, getImageURL(get(data)?.scryfallImageURL || get(data)?.tcgImageURL || ''));
  set(name, get(data)?.displayName || get(data)?.name || '');
  set(description, get(data)?.description || '');
  set(game, get(data)?.game || '');
  set(layout, get(data)?.layout || '');
  set(mp, get(data)?.marketPrice || null);
  set(productUrl, get(data)?.tcgProductURL || '');
  set(rarity, get(data)?.rarity || '');
  set(setName, get(data)?.setName || '');
  set(cardType, get(data)?.cardType || '');
  set(flipImage, getImageURL(get(data)?.scryfallFlipImageURL || get(data)?.tcgFlipImageURL || ''));

  set(cardData, {
    flipImageURL: get(flipImage),
    imageURL: get(image),
    layout: get(layout),
    name: get(name),
    rarity: get(rarity),
    setName: get(setName),
    description: get(description),
    marketPrice: get(mp),
    productUrl: get(productUrl),
    pricePoints: get(pricePoints),
    tcgPlayerID: get(tcgPlayerID),
    cardType: get(cardType),
    game: get(game),
  });

  return Api.getPricePoints(get(tcgPlayerID));
};

const processPricePointsResponse = (response) => {
  const priceData = response?.data || [];

  for (let i = 0; i < priceData.length; i++) {
    const {
      printingType, marketPrice, buylistMarketPrice, listedMedianPrice,
    } = priceData[i];
    get(pricePoints)[printingType].marketPrice = marketPrice;
    get(pricePoints)[printingType].buylistMarketPrice = buylistMarketPrice;
    get(pricePoints)[printingType].listedMedianPrice = listedMedianPrice;
  }
};

async function onVisible() {
  if (props.cardId) {
    if (props.isProduct) {
      await Api.getProductByID(props.cardId)
        .then(response => processResponse(response))
        .then(response => processPricePointsResponse(response))
        .catch((err) => {
          set(error, true);
        });
    } else {
      let { variantSet } = props;

      if ((get(article)?.format || '').toLowerCase() === 'speed duel') {
        variantSet = 'speed duel';
      }

      await Api.getCard(props.cardId, props.cardVertical.toLowerCase(), props.variantId, variantSet, true)
        .then(response => processResponse(response))
        .then(response => processPricePointsResponse(response)).catch((err) => {
          set(error, true);
        });
    }
  } else {
    if (props.cardName) {
      set(name, props.cardName);
    }
    if (props.cardImage) {
      set(image, props.cardImage);
    }
    if (props.cardFlipImage) {
      set(flipImage, props.cardFlipImage);
    }
    if (props.cardProductUrl) {
      set(productUrl, props.cardProductUrl);
    }
    if (props.priceMarket) {
      set(mp, props.priceMarket);
    }
  }

  if (get(name) && get(image)) {
    set(render, true);
  }
}

const sendClickEvent = () => {
  amplitudeEvent('martech', 'buyButton', {
    component: get(componentName),
    title: get(article)?.title,
    type: 'card',
    name: get(name),
    game: verts.displayName(game.value),
    utm_source: get(author)?.analyticsSource,
    itm_source: get(author)?.analyticsSource,
    author: get(author)?.name,
    date_published: get(article)?.dateTime,
    format: get(article)?.format,
    tags: get(article)?.tags,
  });
};
</script>