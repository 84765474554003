<template>
  <div ref="root" class="article-hero" :data-testid="componentID()">
    <router-link v-show="internalTags.includes('channelfireball')" to="/magic-the-gathering/channelfireball" class="logo-link" tabindex="0">
      <div class="cfb-logo-wrapper">
        <img :src="cfbLogo" alt="ChannelFireball" class="cfb-logo">
      </div>
    </router-link>
    <img v-if="hasRoot" :src="imageURL(image || fallbackImage, { maxDimensions: 'autoxauto', quality: 75, root })" :alt="title" :class="[{'fallback' : !image}, 'image']" @error="errorImageFallback($event)">
    <div v-show="data.iconImageURL && taxonomyName" class="series-overlay">
      <span class="label">TCGplayer Series:</span>
      <router-link :to="seriesRoute" class="series-logo-link" tabindex="0">
        <img :src="data.iconImageURL" :alt="taxonomyName" class="series-logo"> {{ taxonomyName }}
      </router-link>
    </div>

    <div class="martech-card-overlay"/>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { ImageHelper, useImageAlter } from '@tcgplayer/martech-components';

const props = defineProps({
  data: {
    type: Object,
    required: true,
    default: null,
  },
  vertical: {
    type: String,
    required: true,
    default: '',
  },
  internalTags: {
    type: Array,
    default: () => [],
  },
});

const root = ref();

const { imageURL, hasRoot } = useImageAlter();

const image = computed(() => props.data?.imageURL || '');
const title = computed(() => props.data?.title || '');
const taxonomyName = computed(() => props.data?.taxonomyName || '');
const fallbackImage = computed(() => ImageHelper.getFallbackImage(props.vertical, 'hero'));

const cfbLogo = 'https://mktg-assets.tcgplayer.com/content/channel-fireball/site-images/cfb-orange-white.svg';

const errorImageFallback = (event) => {
  event.target.src = fallbackImage.value;
};

const seriesRoute = computed(() => `/series/${props.data?.taxonomyName?.toLowerCase()?.replaceAll('-', '--').replaceAll(' ', '-')}`);
</script>

<style lang="scss" scoped>
.article-hero {
  width: 100%;
  height: 200px;
  position: relative;
  margin: $martech-spacer-4 0;

  @include breakpoint(768) {
    height: 520px;
  }

  @include breakpoint(1024) {
    margin: 0 0 $martech-spacer-4 0;
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top center;
    padding: 0;
  }

  .cfb-logo-wrapper {
    width: 100%;
    height: 40px;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $martech-spacer-2 $martech-spacer-3;
    background-color: rgb(0,0,0, 0.5);

    @include breakpoint(1024) {
      padding: $martech-spacer-3;
      height: auto;
    }

    .cfb-logo {
      height: 20px;

      @include breakpoint(1024) {
        height: 30px;
      }
    }
  }

  .series-overlay {
    position: absolute;
    bottom: 16px;
    z-index: 10;
    left: 16px;
    color: $martech-white;

    .label {
      font-size: $martech-type-12;
      text-transform: uppercase;
      font-weight: $martech-weight-semibold;
    }
  }

  .series-logo-link {
    display: flex;
    align-items: center;
    margin-top: 8px;
    background-color: $martech-black;
    padding: 4px 8px;
    border-radius: 8px;
    color: $martech-white;
    font-weight: $martech-weight-bold;
    text-transform: uppercase;
    font-family: $martech-display-semi-condensed;
    font-size: $martech-type-16;
  }

  .series-logo {
    width: 35px;
    margin-right: 8px;
  }

  .martech-card-overlay {
    height: 150px;
  }
}
</style>
