<template>
  <div class="related-content" :data-testid="componentID()" :class="[{'mint-line' : deviceType === 'desktop'}, {'orange-brand' : siteConfig('relatedContentTemplate.orangeBrand')}]">
    <div class="related-articles__grid">
      <slot name="channelfireball"/>
      <slot name="colors-carousel"/>
      <slot name="series-carousel"/>
      <slot name="related-grid"/>
      <slot name="more-in-tag"/>
    </div>
  </div>
</template>
<script>
import deviceType from '@/mixins/deviceType';

export default {
  name: 'related-content-template',
  mixins: [ deviceType ],
  props: {
    title: {
      type: String,
      default: () => {},
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.related-content {
  background-color: $martech-white;
  padding: 0 0 $martech-spacer-4 0;

  &.mint-line {
    border-top: 2px solid $martech-mint;
  }

  &.orange-brand {
    border-color: $martech-cfb-orange-base;
  }
}

:deep(.topnews-carousel) {
  padding: $martech-spacer-4 0;
}
</style>
