import { LinkHelper as link } from '@tcgplayer/martech-components';

export default {
  stripScheme(url) {
    return url.replace(/^https?:\/\//, '');
  },
  getSignInUrl(returnUrl) {
    return `${process.env.VUE_APP_TCGPLAYER_ROOT_URL}/login?returnUrl=${this.stripScheme(returnUrl)}`;
  },
  getSignOutUrl(returnUrl) {
    return `${process.env.VUE_APP_TCGPLAYER_STORE_URL}/home/logout?returnUrl=${this.stripScheme(returnUrl)}`;
  },
  getPaymentUrl(returnUrl) {
    return `${process.env.VUE_APP_TCGPLAYER_STORE_URL}/myaccount/payments?returnUrl=${returnUrl}`;
  },
  urlUtm(analyticsData, url) {
    const data = analyticsData.analyticsSource ? {
      utm_source: analyticsData?.analyticsSource,
      utm_medium: analyticsData?.analyticsMedium,
      utm_campaign: analyticsData?.analyticsCampaign,
      utm_content: analyticsData?.analyticsContent,
    } : analyticsData;

    return link.urlUtmBuilder(url, data);
  },
  setReturnURL(key, url) {
    if (!key) return;

    if (!url || !url.match(/^(https?:\/\/)?.*\.tcgplayer(-qa|-stg)?.com(\/.*)?$/gi)) {
      sessionStorage.removeItem(key);
      return;
    }

    sessionStorage.setItem(key, url);
  },
  getReturnURL(key) {
    return sessionStorage.getItem(key);
  },
  isTcgPlayerDomain(url) {
    if (!url) return false;

    url = new URL(url);

    const pieces = url.hostname.split('.');
    const domain = `.${pieces[pieces.length - 2]}.${pieces[pieces.length - 1]}`;
    return [
      '.tcgplayer-dev.com',
      '.tcgplayer-qa.com',
      '.tcgplayer-stg.com',
      '.tcgplayer.com'
    ].includes(domain);
  },
};
