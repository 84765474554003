<template>
  <div class="spotlight" :data-testid="componentID()">
    <delay-load @visible="onVisible">
      <martech-card-spotlight
        v-if="render"
        :data="cardData"
        :loaded="render"
        :is-flipped="isFlipped"
        :card-product-url="cardBuyUrl"
        :card-id="cardId"
        :is-product="isProduct"
        default-tab="details"
        :image-dimensions="imageDimensions"
        :feature-flag="isOpenLinkSameTabEnabled"
        @amplitude-event="sendClickEvent"
        @flip="isFlipped = !isFlipped"/>
    </delay-load>
    <placeholder v-if="!render && !error" :image-height="deviceType === 'mobile' ? '656px' : '490px'" :placeholder-height="deviceType === 'mobile' ? '708px' : '550px'"/>
    <div v-else-if="!render && error" class="spotlight-alert">
      <alert
        type="danger"
        rounded-corners
        centered-text
        alert-title="Product Not Found"
        alert-text="Sorry, this product is no longer available or may have been deleted."/>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { get, set } from '@vueuse/core';

import Api from '@/api/api';
import useSpotlightCore from '@/use/spotlights';

import {
  MartechCardSpotlight, Alert, useDeviceType
} from '@tcgplayer/martech-components';
import DelayLoad from '@/components/DelayLoad.vue';
import Placeholder from '@/components/placeholder-components/FullArtPlaceholder.vue';

const props = defineProps({
  cardFlipImage: {
    type: String,
    required: false,
    default: '',
  },
  cardId: {
    type: [ Number, String, ],
    required: false,
    default: '',
  },
  isProduct: {
    type: Boolean,
    required: false,
    default: false,
  },
  show: {
    type: Boolean,
    required: false,
    default: true,
  },
  cardName: {
    type: String,
    required: false,
    default: '',
  },
  cardImage: {
    type: String,
    required: false,
    default: '',
  },
  priceMarket: {
    type: Number,
    required: false,
    default: null,
  },
  cardProductUrl: {
    type: String,
    required: false,
    default: '',
  },
  cardVertical: {
    type: String,
    required: true,
    default: 'Magic',
  },
  variantId: {
    type: String,
    required: false,
    default: '',
  },
  variantSet: {
    type: String,
    required: false,
    default: '',
  },
  isCardImage: {
    type: Boolean,
    required: false,
    default: false,
  },
});
const { deviceType } = useDeviceType();

const isOpenLinkSameTabEnabled = true;

const {
  article, cardData, cardBuyUrl, flipImage, image, name, mp, productUrl, processCardResponse, processProductResponse, processPricePointsResponse, sendClickEvent,
} = useSpotlightCore(props.cardName);

const render = ref(false);
const error = ref(false);
const isFlipped = ref(false);

const imageDimensions = computed(() => {
  if (props.isProduct) {
    if (props.isCardImage) {
      return { width: '381px', height: '532px' };
    }

    return get(deviceType) === 'desktop' ? { width: '300px', height: 'auto' } : { width: '310px', height: 'auto' };
  }

  // For lorcana location cards that have a horizontal orientation
  if (get(cardData)?.types.includes('Location')) {
    return get(deviceType) === 'desktop' ? { width: '375px', height: '245px' } : { width: '310px', height: '225px' };
  }

  return get(deviceType) === 'desktop' ? { width: '381px', height: '532px' } : { width: '310px', height: '432px' };
});

async function onVisible() {
  if (props.cardId) {
    if (props.isProduct) {
      await Api.getProductByID(props.cardId)
        .then(response => processProductResponse(response))
        .then(response => processPricePointsResponse(response))
        .catch((err) => {
          set(error, true);
        });
    }

    if (props.cardName) return set(name, props.cardName);
    if (props.cardImage) return set(image, props.cardImage);
    if (props.flipImage) return set(flipImage, props.cardFlipImage);
    if (props.cardProductUrl) return set(productUrl, props.cardProductUrl);
    if (props.priceMarket) return set(mp, props.priceMarket);

    let { variantSet } = props;

    if ((get(article)?.format || '').toLowerCase() === 'speed duel') {
      variantSet = 'speed duel';
    }

    await Api.getCard(props.cardId, props.cardVertical.toLowerCase(), props.variantId, variantSet, true)
      .then(response => processCardResponse(response))
      .catch((err) => {
        set(error, true);
      });

    await Api.getPricePoints(get(cardData)?.tcgPlayerID)
      .then(response => processPricePointsResponse(response))
      .catch(() => {});

    if (get(cardData)?.name && get(cardData)?.imageURL) return set(render, true);
  }

  return set(render, false);
}
</script>

<style lang="scss" scoped>
:deep(.placeholder) {
   @include breakpoint(1024) {
    width: 630px;
    display: block;
    margin: auto;
  }
}
.spotlight {
  margin-bottom: $martech-spacer-3;
}

.spotlight-alert {
  height: 590px;
  display: flex;
  align-items: center;
  justify-content: center;

  @include breakpoint(1024) {
    height: 554px
  }
}
</style>
