import verticalHelpers from '@/lib/verticals';

export default {
  homepage: {
    topNews: true,
    featuredRequiredVerticals: {
      magic: 1,
      yugioh: 1,
      pokemon: 1,
      lorcana: 1,
      'flesh and blood': 1,
    },
    latestRequiredVerticals: {
      magic: 3,
      yugioh: 3,
      pokemon: 2,
      lorcana: 1,
      'flesh and blood': 1,
    },
    latestEvents: {
      display: true,
      includedVerticals: 'magic|yugioh|lorcana',
    },
    latestCombinedDecks: {
      display: true,
      count: 4,
      itemCount: 8,
    },
    videoBlock1: {
      display: true,
      platform: 'YouTube',
    },
    videoBlock2: {
      display: true,
      platform: 'Twitch',
    },
    tags: {
      tag1: {
        tag: 'finance',
        title: 'Finance',
      },
      tag2: {
        tag: 'budget',
        title: 'Budget',
      },
      tag3: {
        tag: 'casual',
        title: 'Casual',
      },
    },
    popular: 'TCGplayer',
  },
  verticalLanding: {
    showBestiary: [ 'magic' ],
    showTopicsSubNav: true,
    showSeriesDropdown: true,
    latestEvents: [ 'magic', 'yugioh', ],
    latestDecks: [ 'magic', 'yugioh', 'lorcana', ],
    latestDecksType: {
      magic: 'featured',
      yugioh: 'featured',
      lorcana: 'latest',
    },
    editorsPicks: true,
    formatSubNav: {
      verticals: [ 'magic', 'yugioh', 'lorcana', 'flesh and blood', ],
      hideFormats: {
        magic: [ 'brawl', 'historic', 'vintage', ],
        yugioh: [ 'traditional', ],
      },
      filteredMagicDeckFormats: [ 'brawl', 'commander', 'historic', 'legacy', 'modern', 'pauper', 'pioneer', 'standard', 'vintage' ],
      filteredMagicEventFormats: [ 'historic', 'legacy', 'limited', 'modern', 'pauper', 'pioneer', 'standard', ],
      events: true,
    },
    wideContent: false,
  },
  navigation: {
    logo: 'https://tcgplayer-cdn.tcgplayer.com/infinite/images/tcgplayer-infinite-logo-black.svg',
    logoAltText: 'TCGplayer infinite',
    customLogoWidth: false,
    premier: [],
  },
  footer: {
    orangeBrand: false,
  },
  account: {
    darkMode: false,
    buttonStyle: 'martech-black-outline',
    iconStyle: 'martech-black',
  },
  relatedContentTemplate: {
    orangeBrand: false,
  },
  global: {
    dripTag: 'General Infinite Content',
    darkMode: false,
    domain: 'content',
    popularVerticals: [ 'magic', 'yugioh', 'pokemon', 'lorcana', 'flesh and blood', ],
    moreGames: verticalHelpers.moreGames,
    moreGamesLinks: verticalHelpers.moreGamesLinks,
    magicFormats: {
      brawl: 'brawl',
      commander: 'commander',
      historic: 'historic',
      legacy: 'legacy',
      limited: 'limited',
      modern: 'modern',
      pauper: 'pauper',
      pioneer: 'pioneer',
      standard: 'standard',
      vintage: 'vintage',
      freeform: 'freeform',
    },
    channelfireballFormats: {
      legacy: 'legacy',
      limited: 'limited',
      modern: 'modern',
      pauper: 'pauper',
      pioneer: 'pioneer',
      standard: 'standard',
      vintage: 'vintage',
    },
    fallback: {
      image: {
        basePath: 'https://tcgplayer-cdn.tcgplayer.com/infinite/images/fallback/',
        card: 'https://tcgplayer-cdn.tcgplayer.com/infinite/images/ghost_imageFallbackPlaceholder%402x.png',
        ghosty: 'https://tcgplayer-cdn.tcgplayer.com/infinite/images/ghosty-fallback-image.png',
        ghostysvg: 'https://tcgplayer-cdn.tcgplayer.com/infinite/images/ghosty_mc_ghostface_ghost-only.svg',
      },
    },
    orangeBrand: false,
    socialIcons: {
      facebook: 'https://tcgplayer-cdn.tcgplayer.com/infinite/images/facebook-blue.svg',
      twitter: 'https://tcgplayer-cdn.tcgplayer.com/infinite/images/twitter-blue.svg',
      instagram: 'https://tcgplayer-cdn.tcgplayer.com/infinite/images/instagram-blue.svg',
      youtube: 'https://tcgplayer-cdn.tcgplayer.com/infinite/images/youtube-blue.svg',
      patreon: 'https://tcgplayer-cdn.tcgplayer.com/infinite/images/patreon-blue.svg',
      twitch: 'https://tcgplayer-cdn.tcgplayer.com/infinite/images/twitch-blue.svg',
      bluesky: 'https://tcgplayer-cdn.tcgplayer.com/infinite/images/bluesky-blue.svg',
    },
    opengraph: {
      image: 'https://tcgplayer-cdn.tcgplayer.com/infinite/images/tcg-content-opengraph.png',
      description: 'Strategy, articles, news, decks, and price guides for Magic: The Gathering, Yu-Gi-Oh!, and more.',
    },
  },
  autocomplete: {
    magicDeckSearch: true,
    yugiohDeckSearch: true,
  },
  search: {
    filterOptions: verticalHelpers.filterOptions,
    placeholder: 'Search Infinite',
  },
};
