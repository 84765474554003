<template>
  <div id="account-menu" class="account-drawer" :class="navDrawerVisibility" tabindex="-1" :data-testid="componentID()">
    <div class="account-drawer--body shadow" :class="{'dark-mode' : siteConfig('account.darkMode')}">
      <div v-if="subscriptionStatus !== 'active'" class="account-drawer__subs-callout" :class="navDrawerVisibility">
        <promos-banner-vertical
          vertical="subscription account"
          :domains="siteConfig('global.domain')"
          placement="subscription promo account"
          promo-size="square"/>
      </div>
      <div v-if="userName" class="account-drawer__header">
        <div class="user">
          <p class="martech-one-line">
            {{ userName }}
          </p>
        </div>
      </div>
      <div v-if="hasRoleAffiliateDeckCreation" class="account-drawer__affiliate">
        <p class="martech-text-md martech-text-semibold">
          Content Contributor
        </p>
        <base-link
          :link-url="{ name: 'submitDeck' }"
          class="nav-link martech-text-md"
          tabindex="0"
          @click="linkClick('/account/submit-deck', 'Affiliate Submit Deck', 'account')">
          Submit Deck
        </base-link>
        <base-link
          :link-url="{ name: 'manageDecks' }"
          class="nav-link martech-text-md"
          tabindex="0"
          @click="linkClick('/account/manage-decks', 'Affiliate Manage Decks', 'account')">
          My Decks
        </base-link>
      </div>
      <div v-if="userName" class="account-drawer__links">
        <ul>
          <li v-for="(item, index) in menu" v-show="!item.isSubscribed || subscriptionStatus" :key="index" tabindex="-1" :class="{'dark-mode' : siteConfig('account.darkMode')}">
            <base-link
              :link-url="item.link"
              class="nav-link martech-text-md"
              tabindex="0"
              :url-target="featureFlagUrlTarget"
              :new-window="!isOpenLinkSameTabEnabled && item.externalLink"
              @click="linkClick(item.link, item.title, 'account')">
              {{ item.title }}
            </base-link>
            <i v-show="!isOpenLinkSameTabEnabled" class="martech-icon martech-external-link-icon" :class="siteConfig('account.iconStyle')"/>
          </li>
        </ul>
      </div>
      <div class="account-drawer__log-button">
        <base-button v-if="userName" :btn-style="siteConfig('account.buttonStyle')" btn-size="martech-small" @clicked="signOut">
          Log Out
        </base-button>
        <base-button v-else :is-disabled="loading" :btn-style="siteConfig('account.buttonStyle')" btn-size="martech-small" @clicked="loginRedirect">
          Log In to TCGplayer
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { mapFields } from 'vuex-map-fields';
import { mapState, mapActions } from 'vuex';
import amplitudeEvent, { setUserProperties as amplitudeUserProperties } from '@tcgplayer/amplitude';
import {
  BaseButton, BaseLink, PromosBannerVertical, Auth, useHasRole
} from '@tcgplayer/martech-components';
import useLinksSameTab from '@/use/featureFlags/useLinksSameTab';

export default {
  name: 'account-menu',
  components: {
    BaseButton,
    BaseLink,
    PromosBannerVertical,
  },
  props: {
    subscriptionStatus: {
      type: String,
      required: false,
      default: '',
    },
    userName: {
      type: String,
      required: false,
      default: '',
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const { hasRole: hasRoleAffiliateDeckCreation } = useHasRole('affiliateDeckCreation');
    const { isOpenLinkSameTabEnabled, featureFlagUrlTarget } = useLinksSameTab();

    const menu = computed(() => {
      const m = [
        {
          title: 'My Account',
          link: `${process.env.VUE_APP_TCGPLAYER_STORE_URL}/myaccount/buyeraccount`,
          externalLink: true,
        },
        {
          title: 'Order History',
          link: `${process.env.VUE_APP_TCGPLAYER_STORE_URL}/myaccount/orderhistory`,
          externalLink: true,
        },
        {
          title: 'Messages',
          link: `${process.env.VUE_APP_TCGPLAYER_STORE_URL}/myaccount/messagecenter`,
          externalLink: true,
        },
        {
          title: 'My Collection',
          link: `${process.env.VUE_APP_TCGPLAYER_STORE_URL}/collection`,
          externalLink: true,
        },
        {
          title: 'Change Password',
          link: `${process.env.VUE_APP_TCGPLAYER_STORE_URL}/myaccount/accountdata`,
          externalLink: true,
        },
        {
          title: 'Manage Payment Methods',
          link: `${process.env.VUE_APP_TCGPLAYER_STORE_URL}/myaccount/payments`,
          externalLink: true,
        },
        {
          title: 'Manage Addresses',
          link: `${process.env.VUE_APP_TCGPLAYER_STORE_URL}/myaccount/addresses`,
          externalLink: true,
        },
      ];

      const sub = {
        title: 'Manage Subscription',
        link: `${process.env.VUE_APP_TCGPLAYER_ROOT_URL}/subscription`,
        externalLink: false,
        isSubscribed: props.subscriptionStatus !== null,
      };

      return [ ...m, sub, ...[
        {
          title: 'Store Credit',
          link: `${process.env.VUE_APP_TCGPLAYER_STORE_URL}/myaccount/storecredit`,
          externalLink: true,
        },
        {
          title: 'Email Preferences',
          link: `${process.env.VUE_APP_TCGPLAYER_ROOT_URL}/account/emailpreferences`,
          externalLink: true,
        },
      ] ];
    });

    return {
      hasRoleAffiliateDeckCreation,
      menu,
      isOpenLinkSameTabEnabled,
      featureFlagUrlTarget,
    };
  },
  data() {
    return {
      subscriptionLandingURL: `${process.env.VUE_APP_TCGPLAYER_ROOT_URL}/subscription?utm_source=infinite&utm_medium=infinite_nav_profile&utm_campaign=infinite_nav_profile_11112022`,
    };
  },
  computed: {
    ...mapState('sidebar', {
      isAccountVisible: 'isAccountVisible',
    }),
    ...mapFields('subscription', [ 'subscription', ]),
    navDrawerVisibility() {
      return {
        'is-active': this.isAccountVisible,
        'subscriptions-nav': this.$route.meta.changeNav === 'subscriptions',
      };
    },
  },
  mounted() {
    document.addEventListener('keyup', this.escapeAccDrawer);
  },
  unmounted() {
    document.removeEventListener('keyup', this.escapeAccDrawer);
  },
  methods: {
    ...mapActions({
      toggleAccountDrawer: 'sidebar/toggleAccountDrawer',
    }),
    escapeAccDrawer(event) {
      if (event.keyCode === 27) {
        this.toggleAccountDrawer(false);
      }
    },
    linkClick(linkURL, linkTitle, linkParent) {
      amplitudeEvent('infinite', 'infiniteNavigationClicked', {
        linkURL,
        linkTitle,
        linkParent,
        location: 'primary',
      });
    },
    async loginRedirect() {
      const [ linkClick, loginUrl ] = await Promise.all([ Auth.getLoginUrl(''), Auth.getLoginUrl(window.location.href) ]);

      // Might as well have a matching URL for this as we have the current path as a separate item
      this.linkClick(linkClick, 'Log In to TCGplayer', 'account');
      window.location = loginUrl;
    },
    async signOut() {
      amplitudeUserProperties({ userId: null });
      const logout = await Auth.getLogoutUrl(process.env.VUE_APP_BASE_URL);
      window.location = logout;
    },
  },
};
</script>

<style lang="scss" scoped>
.subscriptions-nav {
  .account-drawer {
    right: -15px;
    top: 5px;
  }
}

.account-drawer {
  position: absolute;
  top: -10px;
  z-index: 1000;
  right: -48px;
  padding: 48px 0 0;

  @include breakpoint(1024) {
    padding: 30px;
    top: 10px;
    right: -40px;
  }

  @include breakpoint(1200) {
    top: 5px;
  }

  &--body {
    background: $martech-white;
    border-radius: $martech-radius-default;
    width: 250px;

    &.dark-mode {
      background: $martech-gray-160;

      .user {
        p {
          color: $martech-white;
        }
      }
    }
  }

  &__subs-callout {
    position: relative;
    border-top-right-radius: $martech-radius-default;
    border-top-left-radius: $martech-radius-default;
    width: 100%;
    overflow: hidden;
    display: block;

    :deep(.martech-promos-banner) {
      .martech-promos-wrapper {
        border-radius: 0px;
      }

      a {
        &:focus-visible {
          display: inline-block;
          outline: 2px dashed $martech-blue-120;
          margin: 3px 0;
        }
      }
    }

    &.subscriptions-nav {
      display: none;
    }

    img {
      position: absolute;
      object-fit: cover;
      object-position: top center;
      width: 100%;
      height: 100%;
      top: 0;
      bottom: 0;
      z-index: 0;
    }

    .content {
      position: absolute;
      z-index: 1;
      color: $martech-white;
      padding: $martech-spacer-3;

      .heading {
        padding-bottom: $martech-spacer-3;
      }

      .learn-more {
        display: flex;
        align-items: center;
      }

      .martech-chevron {
        width: 14px;
        margin-left: $martech-spacer-2;
      }
    }
  }

  &__header {
    height: 48px;
    border-bottom: 1px solid $martech-border;
    padding: 0 $martech-spacer-3;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .user {
      width: 100%;

      p {
        color: $martech-gray-160;
        font-size: $martech-type-12;
        letter-spacing: 0.6px;
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
      }
    }
  }

  &__affiliate {
    border-bottom: 1px solid $martech-border;
    padding: $martech-spacer-2 $martech-spacer-3;
    display: flex;
    flex-direction: column;

    .martech-text-md {
      line-height: $martech-type-24;
    }
  }

  &__links {
    padding: $martech-spacer-3 0;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        height: 28px;
        padding: 0 $martech-spacer-3;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:hover {
          background-color: $martech-blue-5;
        }

        &.dark-mode {
          &:hover {
            background: transparent;
          }

          .nav-link {
            :deep(a) {
              color: $martech-white;

              &:hover {
                color: $martech-cfb-orange-base;
              }
            }
          }
        }
      }
    }
  }

  .nav-link {
    :deep(a) {
      width: 90%;
      color: $martech-text-primary;
    }
  }

  &__log-button {
    padding: $martech-spacer-3;

    :deep(.martech-button) {
      width: 100%;
    }
  }
}
</style>
