<template>
  <div v-if="heroData" ref="root" :data-testid="componentID()" class="hero-wrapper">
    <div class="container">
      <h1 class="martech-heading martech-text-semibold martech-semi-condensed martech-text-uppercase">
        TCGplayer Content
      </h1>
      <p>
        Elevate Your Game
      </p>
    </div>
    <hero :data="heroData" :on-click="analyticsEvent" :full-width="false" :has-h1="false"/>
  </div>
  <div v-else class="homepage-hero-no-data"/>
</template>

<script>
import amplitudeEvent from '@tcgplayer/amplitude';
import { Hero } from '@tcgplayer/martech-components';

export default {
  name: 'homepage-hero',
  components: {
    Hero,
  },
  props: {
    heroData: {
      type: Object,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      hasRoot: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.root) {
        this.hasRoot = true;
      }
    });
  },
  methods: {
    analyticsEvent() {
      const match = this.heroData.buttonURL.match(/article\/(.+)/);
      const articleTitle = (match && match.length > 1) ? match[1] : '';
      amplitudeEvent('infinite', 'infiniteHeroClicked', {
        articleTitle,
        path: this.heroData.buttonURL,
        title: this.heroData.title,
        productLine: this.heroData.vertical,
        productAffinity: this.heroData.productLineAffinity,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.hero-wrapper {
  margin-top: 24px;
}

h1 {
  margin-bottom: 8px;
}

p {
  margin-bottom: 16px;
}

.homepage-hero-no-data {
  width: 100%;
  min-height: 414px;

  @include breakpoint(1024) {
    min-height: 358px;
  }
}
</style>
