import { computed } from 'vue';

export default function useLinksSameTab() {
  const isOpenLinkSameTabEnabled = true;
  const featureFlagUrlTarget = computed(() => (isOpenLinkSameTabEnabled ? false : '_blank'));

  return {
    isOpenLinkSameTabEnabled,
    featureFlagUrlTarget,
  };
}
