<template>
  <div id="article-side-rail" class="side-rail" :data-testid="componentID()">
    <div
      v-if="promoOneState && deviceType === 'desktop'"
      ref="promoOne"
      class="side-rail-item promos-one">
      <div class="sticky-scroll">
        <promos-banner-vertical
          :content-types="contentTypes"
          :vertical="verticalLower"
          :promo-size="'medium rectangle'"
          :domains="siteConfig('global.domain')"
          @no-data="promoOneState = false"
          @loaded="promoOneState = 'loaded'"/>
      </div>
    </div>
    <!-- Author More Content's relative position depends on screen size -->
    <div v-if="[ 'xs', 'sm', 'md', ].includes(deviceTypeSpecific)" ref="authorWidget" class="side-rail-item">
      <div class="sticky-scroll author-widget">
        <author-more-content/>
      </div>
    </div>
    <div v-if="popularState" ref="popularWidget" class="side-rail-item">
      <div class="sticky-scroll">
        <popular-content
          :vertical="popularVertical"
          :exclude="exclude"
          :rows="6"
          :mobile-scroll="true"
          @no-data="popularState = false"
          @loaded="popularState = 'loaded'"/>
      </div>
    </div>
    <div
      v-if="promoTwoState && deviceType === 'desktop'"
      ref="promoTwo"
      class="side-rail-item promos-two">
      <div class="sticky-scroll">
        <promos-banner-vertical
          :content-types="contentTypes"
          :vertical="verticalLower"
          :domains="siteConfig('global.domain')"
          :promo-size="'halfpage'"
          @no-data="promoTwoState = false"
          @loaded="promoTwoState = 'loaded'"/>
      </div>
    </div>
    <!-- Author More Content's relative position depends on screen size -->
    <div v-if="[ 'lg', 'xl', ].includes(deviceTypeSpecific)" ref="authorWidget" class="side-rail-item">
      <div class="sticky-scroll author-widget">
        <author-more-content/>
      </div>
    </div>
    <div
      v-if="promoThreeState && deviceType === 'desktop'"
      ref="promoThree"
      class="side-rail-item promos-three">
      <div class="sticky-scroll">
        <promos-banner-vertical
          :content-types="contentTypes"
          :vertical="verticalLower"
          :domains="siteConfig('global.domain')"
          :promo-size="'medium rectangle'"
          @no-data="promoThreeState = false"
          @loaded="promoThreeState = 'loaded'"/>
      </div>
    </div>
  </div>
</template>

<script>
import { PromosBannerVertical } from '@tcgplayer/martech-components';
import AuthorMoreContent from '@/components/article-widgets/AuthorMoreContent.vue';
import PopularContent from '@/components/article-widgets/PopularContent.vue';
import deviceType from '@/mixins/deviceType';

export default {
  name: 'article-side-rail',
  components: {
    AuthorMoreContent,
    PopularContent,
    PromosBannerVertical,
  },
  mixins: [ deviceType ],
  props: {
    vertical: {
      type: String,
      default: '',
      required: false,
    },
    contentTypes: {
      type: String,
      required: false,
      default: 'article',
    },
    exclude: {
      type: String,
      default: '',
      required: false,
    },
  },
  data() {
    return {
      promoOneState: 'init',
      promoTwoState: 'init',
      promoThreeState: 'init',
      popularState: 'init',
      observer: new MutationObserver(this.setPromo),
    };
  },
  computed: {
    verticalLower() {
      return this.vertical.toLowerCase();
    },
    popularVertical() {
      return this.siteConfig('global.popularVerticals').includes(this.verticalLower) ? this.vertical : null;
    },
  },
  mounted() {
    const targetNode = document.getElementById('article-side-rail');
    const config = { attributes: false, childList: true, subtree: true };

    this.observer.observe(targetNode, config);

    // Disconnects window observer after 20seconds
    window.setTimeout(() => {
      this.observer.disconnect();
    }, 20000);
  },
  unmounted() {
    if (this.observer) this.observer.disconnect();
  },
  methods: {
    setPromo() {
      const { articleBodyWrapper } = this.$parent.$refs;
      const { promoOne } = this.$refs;
      const { authorWidget } = this.$refs;
      const { promoTwo } = this.$refs; // half page banner promo
      const { promoThree } = this.$refs; // medium rectangle promo
      if (!articleBodyWrapper) {
        return 0;
      }

      // Get client height of article body
      const bodyHeight = articleBodyWrapper.clientHeight;

      // If the pages body is less than 1200 hide all ads and only display popular if loaded, fall back to author if popular is not loaded.
      if (bodyHeight < 1200) {
        if (promoOne) promoOne.classList.add('hide');
        if (promoTwo) promoTwo.classList.add('hide');
        if (promoThree) promoThree.classList.add('hide');

        if (this.popularState === 'loaded' && authorWidget) {
          authorWidget.classList.add('hide');
        }
      }

      // If page can have both a medium and halfpage promo loaded
      if (this.promoOneState === 'loaded' || this.promoTwoState === 'loaded') {
        if (bodyHeight <= 1500) {
          // if article body is less than or equal to 1100 hide promo two, three and popular content widget. Promo one and author components are static.
          if (promoTwo) promoTwo.classList.add('hide');
          if (promoThree) promoThree.classList.add('hide');

          if (this.popularState === 'loaded' && authorWidget) {
            authorWidget.classList.add('hide');
          }
        } else if (
          this.promoOneState === 'loaded'
          && bodyHeight > 1500
          && bodyHeight <= 2500
        ) {
          // if article body is between 1500px and 3000px inclusive author and popular component split the side rail 50/50 and slide.
          if (promoTwo) promoTwo.classList.add('hide');
          if (promoThree) promoThree.classList.add('hide');
        }
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.side-rail {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  .side-rail-item {
    flex: 1 0 auto;

    &.hide {
      display: none;
    }
  }

  :deep(.popular-content) {
    @include breakpoint(1024) {
      padding-bottom: $martech-spacer-4;
    }
  }

  .sticky-scroll {
    @include breakpoint(1200) {
      position: sticky;
      top: 110px;

      &.author-widget {
        margin-bottom: $martech-spacer-4;
      }
    }
  }
}
</style>
